@import "bootstrap";

.form-container {
    background-color: #C4DBF6 !important;
    padding-top: 100px !important;
    padding-bottom: 100% !important;
    width: 100%;
    height: 100% !important;
  }

.form-container2 {
    background-color: #C4DBF6 !important;
    padding-top: 200px !important;
    padding-bottom: 100% !important;
    width: 100%;
    height: 100% !important;
  }

.form-parent-div {
    background-color: white;
    width: 360px;
    border-radius: 15px;
    box-shadow: 5px 10px 18px #4e4e4edc;
}

.container {
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	height: 100% !important;
}

h2, h3, h4 {
	font-family: 'Raleway', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}

h2 {
	text-transform: uppercase;
	margin: 0 0 20px 0;
	font-weight: 800;
	font-size: 36px;
	color: #243665;
}
h3 {
	font-size: 20px;
	font-weight: 600;
	color: #243665;
}
h4 {
	font-size: 16px;
	color: #243665;
	font-weight: 600;
}
h5 {
  font-size: 15px;
	font-weight: 700;
  line-height: 20px;
  
}
p {
  font-size: 15px;
  color: #243665;
}
p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
  font-size: 15px;
	font-weight: 400;
}
a:hover, a:focus {
	text-decoration: none;
	color: #8BD8BD;
}

.padding-row {
  padding-bottom: 30px;
  //width: 100px;
}

.first-row {
    padding-bottom: 15px;
    //width: 100px;
  }
  
  .name-row {
    padding-bottom: 10px;
  }
  
  .phone-row {
    padding-bottom: 10px;
  }

.login-input {
  font-size: 15px;
	font-weight: 500;
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
}

.login-input:hover {
  background-color: white !important;
  border-color: #c0c5c8 !important;
}

.login-input:focus {
  border-color: #C4DBF6 !important;
  background-color: white !important;
}

.invalid-field.login-input {
  background-color: #f5f8fa !important;
  border-color: #ff5103 !important;
}

.invalid-field:hover.login-input:hover {
  background-color: white !important;
  border-color: #c0c5c8 !important;
}

.invalid-field:focus.login-input:focus {
  border-color: #C4DBF6 !important;
  background-color: white !important;
}

.error-text {
  color: #ff5103;
  font-size: 15px;
}





.title-text {
  font-weight: 700 !important;
  font-size: 30px;
  line-height: 30px !important;
}



.phone-input-back {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
}

.next-btn {
  background-color: #243665 !important;
  border-color: #243665 !important;
  font-weight: 600 !important;
  color: #C4DBF6;
}

.next-btn:hover:enabled {
  background-color: #243665 !important;
}

.btn-custom {
	color: #C4DBF6;
}

.sec-text {
  font-weight: 600;
  padding-left: 5px;
  text-decoration: underline;
}

.info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-btn {
  font-weight: 600 !important;
  text-align: center;
}

.form-control.rbt-input.rbt-input-main {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
}

.form-control:hover.rbt-input:hover.rbt-input-main:hover {
  background-color: white !important;
  border-color: #c0c5c8 !important;
}

.form-control:focus.rbt-input:focus.rbt-input-main:focus {
  border-color: #C4DBF6 !important;
  background-color: white !important;
}

.form-control.rbt-input.rbt-input-main.invalid-field {
  background-color: #f5f8fa !important;
  border-color: #ff5103 !important;
}

.form-control:hover.rbt-input:hover.rbt-input-main:hover.invalid-field:hover {
  background-color: white !important;
  border-color: #c0c5c8 !important;
}

.form-control:focus.rbt-input:focus.rbt-input-main:focus.invalid-field:focus {
  border-color: #C4DBF6 !important;
  background-color: white !important;
}

.react-tel-input .form-control {
  width: inherit !important;
}

.info_col {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #f5f8fa;
  border-radius: 0px 15px 15px 0px;
  @include media-breakpoint-down(sm) {
    border-radius: 0px 0px 15px 15px;
  }
}

.mini-title {
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}
