@import "bootstrap";
@import "../fonts/font-awesome/css/font-awesome.css";


.container {
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	height: 100% !important;
}

h2, h3, h4 {
	font-family: 'Raleway', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}

h2 {
	text-transform: uppercase;
	margin: 0 0 20px 0;
	font-weight: 800;
	font-size: 36px;
	color: #333;
}
h3 {
	font-size: 20px;
	font-weight: 600;
	color: #333;
}
h4 {
	font-size: 18px;
	color: #333;
	font-weight: 600;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p {
	font-size: 15px;
}
p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
	color: #C4DBF6;
	font-weight: 400;
}
a:hover, a:focus {
	text-decoration: none;
	color: #C4DBF6;
}
ul, ol {
	list-style: none;
}
ul, ol {
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}
hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #1E7A46;
	margin-bottom: 20px;
	border: 0;
}

.padding-row {
	padding-bottom: 30px;
	//width: 100px;
  }

.navbar {
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 400px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;

}
/* Navigation */
#menu {
	padding: 15px;
	transition: all 0.8s;
}
.navbar-default {
	background-color: #fff;
	border-color: rgba(231, 231, 231, 0);
	box-shadow: 0 0 10px rgba(0,0,0,0.15)
}
a.navbar-brand {
	font-family: 'Raleway', sans-serif;
	font-size: 24px;
	font-weight: 700;
	color: #333;
	text-transform: uppercase;
}
a.nav-link {
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	color: #555;
	font-size: 15px;
	font-weight: 400;
	padding: 8px 2px;
	border-radius: 0;
	margin: 9px 20px 0 20px;
}


a.nav-link .navbar-default .navbar-nav > li > a:after {
	display: block;
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	content: "";
	transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
	width: 100%;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after, .navbar-default .navbar-nav > .active > a:hover:after, .navbar-default .navbar-nav > .active > a:focus:after {
	display: block !important;
	position: absolute !important;
	left: 0 !important;
	bottom: -1px !important;
	width: 100% !important;
	height: 2px !important;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
	content: "" !important;
	transition: width 0.2s !important;
}
.navbar-toggle {
	border-radius: 0;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #fff;
	border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover>.icon-bar {
	background-color: #608dfd;
}
.section-title {
	margin-bottom: 70px;
}
.section-title h2 {
	position: relative;
	color: #243665;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
	line-height: 1.1;
	font-size: 36px;
	font-weight: 700;
}
.section-title h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
.section-title p {
	font-size: 18px;
	color: #243665;
}
/* .section-title h2 {
	font-size: 36px;
	font-weight: 800;
} */


.btn-custom {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: #C4DBF6;
	//background-color: #5ca9fb;
	background-image: linear-gradient(to right, #243665 0%, #243665 100%);
	//background-color: #C4DBF6;
	border: 2px solid #243665;
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 25px;
	font-weight: 500;
	border-radius: 25px;
	transition: all 0.5s linear;
	//border: 0;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #C4DBF6;
	background-image: none;
	background-color: #243665;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
/* Header Section */
.intro {
	display: table;
	width: 100%;
	padding: 0;
	background: url(../img/intro-bg.jpg) center center no-repeat;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro .overlay {
	background: rgba(0,0,0,0.2);
}
.intro h1 {
	font-family: 'Raleway', sans-serif;
	color: #243665;
	font-size: 70px;
	font-weight: 600;
	//text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 10px;
}
.intro h1 span {
	font-weight: 800;
	color: #5ca9fb;
}
.intro p {
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}
header .intro-text {
	padding-top: 240px;
	padding-bottom: 200px;
	color: #C4DBF6;
	text-align: center;
	vertical-align: center;
}

#intro .btn-custom {
	margin: 5px 0;
	background: #C4DBF6;
	border: 2px solid #243665;
}

/* Features Section */
#features {
	padding: 60px 0;
	background: #f6f6f6;
	
}
#features i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	//background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}


/* Overview Section */
#overview {
	padding: 100px 0;
}
#overview h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#overview h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.1;
}
#overview h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#overview .overview-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#overview .overview-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#overview img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#overview p {
	line-height: 24px;
	margin: 30px 0;
}


/* Course Section */
#course {
	padding: 100px 0;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	background: #C4DBF6;
}
#course h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#course h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	color: #243665;
	line-height: 1.1;
}
#course h2::after {
	position: absolute;
	content: "";
	/*background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);*/
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#course .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#course .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#course img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#course p {
	font-size: 18px;
	line-height: 24px;
	margin: 30px 0;
	color: #243665
}

#course .table-header {
	line-height: 24px;
	margin: 30px 0;
	color: #243665;
	font-size: 20px;
}

#course .table-body {
	line-height: 24px;
	margin: 30px 0;
	color: #243665;
	font-size: 20px;
}

/* Home Section */
#home {
	padding: 60px 0;
	padding-bottom: 340px;
	/*background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);*/
	background: #F6F6F6;
}
#home h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
	color: #243665;
}
#home h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	color: #243665;
	line-height: 1.1;
}

#home .h3-large {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 30px;
	font-weight: 700;
	color: #243665;
	line-height: 1.1;
}

#home h2::after {
	position: absolute;
	content: "";
	/*background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);*/
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#home .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#home .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#home img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#home p {
	line-height: 24px;
	margin: 30px 0;
	color: #243665
}

#home .table-header {
	line-height: 24px;
	margin: 30px 0;
	color: #243665;
	font-size: 22px;
	text-align: left;
	vertical-align: top;
	//border-bottom: 1px solid #000;
}

#home .table-body {
	line-height: 24px;
	margin: 30px 0;
	color: #243665;
	font-size: 16px;
	text-align: left;
	vertical-align: top;
}

#home .btn-custom {
	margin: 5px 0;
	background: #243665;
	color: #C4DBF6;
	border: 2px solid #243665;
}

/* lesson1 Section */
#lesson1 {
	padding: 100px 0;
	background: #ffffff;
}
#lesson1 h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#lesson1 h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.1;
}
#lesson1 h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#lesson1 .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#lesson1 .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#lesson1 img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#lesson1 p {
	line-height: 24px;
	margin: 30px 0;
}

/* lesson2 Section */
#lesson2 {
	padding: 100px 0;
	background: #f6f6f6;
}
#lesson2 h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#lesson2 h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.1;
}
#lesson2 h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#lesson2 .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#lesson2 .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#lesson2 img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#lesson2 p {
	line-height: 24px;
	margin: 30px 0;
}
/* Padding */
#aboutpadding {
	padding-bottom: 640px !important;
	background: #f6f6f6;
	height: 100% !important;
}

/* About Section */
#about {
	padding: 60px 0;
	background: #f6f6f6;
}
#about h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#about h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.1;
	color: #243665;
}
#about h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#about .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#about .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#about img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#about p {
	line-height: 24px;
	margin: 30px 0;
	color: #243665;
}

/* Courses Section */
#courses {
	padding: 60px 0;
	//background: #f6f6f6;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	background: #C4DBF6;
	//background: #C4DBF6;
	//background: #466C82;
	color: #fff;
}
#courses .service-desc {
	margin: 10px 10px 20px;
}
#courses h2 {
	color: #243665;
}
#courses .section-title h2::after {
	position: absolute;
	content: "";
	//background: rgba(255,255,255,.3);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: #C4DBF6;
	height: 4px;
	width: 60px;
	bottom: 0;
	/*margin-left: -30px;*/
	left: 30px;
}
#courses i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	border-radius: 50%;
	color: #243665;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#courses h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #243665;
}
#courses p {
	color: #243665;
}

#courses .section-title {
	margin-bottom: 40px;
}
#courses .service-desc {
	margin-bottom: 40px;
}

#courses .btn-custom {
	margin: 10px 0;
	background: #243665;
	border: 2px solid #243665;
	color: #C4DBF6;
}


/* Upcoming Courses Section */
#upcomingcourses {
	padding: 100px 0;
	background: #ffffff;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	color: #243665;
}
#upcomingcourses .service-desc {
	margin: 10px 10px 20px;
}
#upcomingcourses h2 {
	color: #243665;
}
#upcomingcourses .section-title h2::after {
	position: absolute;
	content: "";
	//background: rgba(255,255,255,.3);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: #C4DBF6;
	height: 4px;
	width: 60px;
	bottom: 0;
	/*margin-left: -30px;*/
	left: 30px;
}
#upcomingcourses i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	border-radius: 50%;
	color: #243665;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#upcomingcourses h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #243665;
}
#upcomingcourses p {
	color:#243665;
}

#upcomingcourses .section-title {
	margin-bottom: 40px;
}
#upcomingcourses .service-desc {
	margin-bottom: 40px;
}

/* Events Section */
#events {
	padding: 60px 0;
	background: #C4DBF6;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	color: #243665;
}

#events p {
	color: #243665;
}
#events .service-desc {
	margin: 10px 10px 20px;
}
#events h2 {
	color: #243665;
}
#events .section-title h2::after {
	position: absolute;
	content: "";
	//background: rgba(255,255,255,.3);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: #C4DBF6;
	height: 4px;
	width: 60px;
	bottom: 0;
	/*margin-left: -30px;*/
	left: 30px;
}
#events i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	border-radius: 50%;
	color: #fff;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#events h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #243665;
}

#events .section-title {
	margin-bottom: 40px;
}
#events .service-desc {
	margin-bottom: 40px;
}

#events .btn-custom {
	margin: 10px 0;
	background: #243665;
	border: 2px solid #243665;
	color: #C4DBF6;
}


/* Past Events Section */
#pastevents {
	padding: 60px 0;
	background: #ffffff;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	color: #243665;
}
#pastevents .service-desc {
	margin: 10px 10px 20px;
}
#pastevents h2 {
	color: #243665;
}
#pastevents .section-title h2::after {
	position: absolute;
	content: "";
	//background: rgba(255,255,255,.3);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: #C4DBF6;
	height: 4px;
	width: 60px;
	bottom: 0;
	/*margin-left: -30px;*/
	left: 30px;
}
#pastevents i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	border-radius: 50%;
	color: #243665;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#pastevents h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #243665;
}
#pastevents p {
	color: #243665;
}

#pastevents .section-title {
	margin-bottom: 40px;
}
#pastevents .service-desc {
	margin-bottom: 40px;
}



/* Services Section */
#services {
	padding: 60px 0;
	background: #ffffff;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	color: #fff;
}
#services .service-desc {
	margin: 10px 10px 20px;
}
#services h2 {
	color: #243665;
}
#services .section-title h2::after {
	position: absolute;
	content: "";
	//background: rgba(255,255,255,.3);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	//background: #C4DBF6;
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
#services i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	border-radius: 50%;
	color: #243665;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#services h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #243665;
}
#services p {
	color:#243665;
}
#services .service-desc {
	margin-bottom: 40px;
}
/* Portfolio Section */
#portfolio {
	padding: 100px 0;
}
.portfolio-item {
	margin: 1px -15px 0 -14px;
	padding: 0;
}
.portfolio-item .hover-bg {
	overflow: hidden;
	position: relative;
	margin: 0;
}
.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: linear-gradient(to right, rgba(99,114,255,0.8) 0%, rgba(92,169,251,0.8) 100%);
	padding: 30% 0 0 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
}
/* Testimonials Section */
#testimonials {
	padding: 100px 0;
	background: #f6f6f6;
}
#testimonials i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}
.testimonial {
	position: relative;
	padding: 20px;
}
.testimonial-image {
	float: left;
	margin-right: 15px;
}
.testimonial-image, .testimonial-image img {
	display: block;
	width: 64px;
	height: 64px;
	border-radius: 50%;
}
.testimonial-content {
	position: relative;
	overflow: hidden;
}
.testimonial-content p {
	margin-bottom: 0;
	font-size: 14px;
	font-style: italic;
}
.testimonial-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #666;
}
/* Team Section */
#team {
	padding: 100px 0;
}
#team h4 {
	margin: 5px 0;
}
#team .team-img {
	width: 240px;
}
#team .thumbnail {
	background: transparent;
	border: 0;
}
#team .thumbnail .caption {
	padding: 10px 0 0 0;
	color: #888;
}

/* Contact Padding */
#contactpadding {
	padding-bottom: 520px !important;
	background: #C4DBF6;
	height: 100% !important;
}

/* Contact Section */
#contact {
	padding: 60px 0 60px 0;
	//background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	background: #C4DBF6;
	color: rgba(255,255,255,.75);
}
#contact .section-title {
	margin-bottom: 40px;
}
#contact .section-title p {
	font-size: 16px;
}
#contact h2 {
	color: #243665;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#contact .section-title h2::after {
	position: absolute;
	content: "";
	//background: rgba(255,255,255,.3);
	background: linear-gradient(to right, #243665 0%, #C4DBF6 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 30px;
}
#contact h3 {
	color: #fff;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}
#contact form {
	padding-top: 20px;
}
#contact .text-danger {
	color: #cc0033;
	text-align: left;
}
#contact .btn-custom {
	margin: 30px 0;
	background: #243665;
	border: 2px solid #243665;
	color: #ffffff;
}
#contact .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contact .contact-item {
	margin: 20px 0;
}
#contact .contact-item span {
	color: rgba(255,255,255,1);
	margin-bottom: 10px;
	display: block;
}
#contact .contact-item i.fa {
	margin-right: 10px;
}
#contact .social {
	border-top: 1px solid #243665;
	padding-top: 50px;
	margin-top: 50px;
	text-align: center;
}
#contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#contact .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid #243664;
	color: #243664;
	border-radius: 50%;
	transition: all 0.3s;
}
#contact .social i.fa:hover {
	color: #608dfd;
	background: #fff;
}
/* Footer Section*/
#footer {
	background: #f6f6f6;
	padding: 30px 0;
}
#footer p {
	color: #888;
	font-size: 14px;
}
#footer a {
	color: #608dfd;
}
#footer a:hover {
	border-bottom: 2px solid #608dfd;
}

@media (max-width: 768px) {
#about img {
	margin: 50px 0;
}
}
